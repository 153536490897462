/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['admin'],
  staff: ['admin', 'staff'],
  user: ['admin', 'staff', 'user', 'customer-cornerstone'],
  ahi: ['ahi'],
  ahi_oncall: ['ahi_oncall'],
  demo: ['demo'],
  free: ['free'],
  elysian: ['admin', 'elysian'], 
  sholom: ['admin', 'sholom'],
  demo2: ['demo2', 'admin'],
  thegenevasuites: ['thegenevasuites'],
  onlyGuest: [],
};

export default authRoles;
