import * as React from 'react';
import { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

// https://react-material.fusetheme.com/documentation/material-ui-components/rating

export const UselessToAbsolutely = {
  0.5: 'I see no value',        // negative
  1: 'Not at all',              // negative
  1.5: 'Very unlikely',         // negative
  2: 'Unlikely',                // negative
  2.5: 'Not Sure',              // neutral
  3: 'Potentially',             // neutral
  3.5: 'Helpful',               // postive
  4: 'Very Helpful',            // postive
  4.5: 'Significantly Helpful', // postive
  5: 'Extremely Helpful'        // postive
};

export const LittleToAlot = {
  1: "None",
  2: 'Not likely',
  3: 'Unsure',
  4: 'Very Helpful',
  5: 'Extremely Helpful',
}

export default function HoverRating(props) {
    const {value, setValue} = props;
    const [hover, setHover] = useState(-1);
    const [labels, setLabels] = useState((!props?.labels)? UselessToAbsolutely: props.labels);
    const [max, setMax] = useState((!props?.labels)? UselessToAbsolutely.length: Object.keys(props.labels).length);
    const [percision, setPercision] = useState((!props?.labels)? 0.5: props.percision)

    const size = (!props?.size)? 'large': props.size;

    
    function getLabelText(value) {
      return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    return (
      <Box
        sx={{
          width: 300,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Rating
          name="hover-feedback"
          value={value}
          precision={percision}
          getLabelText={getLabelText}
          size={size}
          max={max}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        {value !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </Box>
    );
  }