import React, {lazy} from 'react';
// const Layout = lazy(() => import('../modules/chat_regulation/layout'));
// const ChatApp = lazy(() => import('../modules/chat_regulation/ChatApp'));

const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp'));
const PermissionsApp = lazy(() => import('../modules/iam/Layout'));
const UserPage = lazy(() => import('../modules/iam/pages/UsersPage'));
const PermissionsPage = lazy(() => import('../modules/iam/pages/PermissionsPage'));
const RolePage = lazy(() => import('../modules/iam/pages/RolesPage'));
const SearchHistoryReport = lazy(() => import('../modules/reports/SearchHistory/Report'));
const SearchHistoryHomePage = lazy(() => import('../modules/reports/SearchHistory/pages/HomePage'));
//import SearchHistoryHomePage from '../modules/reports/SearchHistory/pages/HomePage'
//import SearchHistoryReport from '../modules/reports/SearchHistory/Report';

const ElysianRoutes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'Elysian',
      element: <Layout />,
      auth: ['admin', 'elysian', 'demo'],
      children: [
        {
          path: '',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            pageTitle="Elysian Resources"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Elysian BrightSources</span>}
          />,
        },
        {
          path: 'Reports',
          auth: ['admin', 'elysian', 'demo'],
          element: <SearchHistoryReport />,
          children: [
            { path: '', element: <SearchHistoryHomePage /> },
          ]
        },
        {
          path: 'Reports/SearchHistory',
          auth: ['admin', 'elysian', 'demo'],
          element: <SearchHistoryReport />,
          children: [
            { path: '', element: <SearchHistoryHomePage /> },
          ]
        },
        {
          path: 'Permissions',
          auth: ['admin', 'elysian'],
          element: <PermissionsApp />,
          children: [
            { path: '', element: <UserPage /> },
            {
              path: 'Users',
              element: <UserPage />,
              children: [
                { path: '', element: <UserPage /> },
                { path: 'Add', element: <UserPage /> },
                { path: 'Edit', element: <UserPage /> },
                { path: 'Invite', element: <UserPage /> },
                { path: 'BulkEdit', element: <UserPage /> },
              ]
            },
            { path: 'Permissions', element: <PermissionsPage /> },
            { path: 'Roles', element: <RolePage /> },
          ]
        },
        {
          path: 'Competencies',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Competencies'
            pageTitle="Elysian Compentencies"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Competencies</span>}
          />,
        },

        {
          path: 'Instructions',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Instructions'
            pageTitle="Elysian Instructions"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Instructions</span>}
          />,
        },
        {
          path: 'OperationsManual',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Operations Manual'
            pageTitle="Elysian Operations Manual"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Emergency Manual</span>}
          />,
        },

        {
          path: 'EmergencyManual',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Emergency Manual'
            pageTitle="Elysian Emergency Manual"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Emergency Manual</span>}
          />,
        },
        {
          path: 'EmploymentDocuments',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Employment Documents'
            pageTitle="Elysian Employment Documents"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Employment Documents</span>}
          />,
        },

        {
          path: 'PoliciesAndProcedures',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Policies and Procedures'
            pageTitle="Elysian Policies & Procedures"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Policies & Procedures</span>}
          />,
        },
        {
          path: 'Regulations',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Regulations'
            pageTitle="Elysian Regulations"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Regulations</span>}
          />,
        },
        {
          path: 'PricingAndMarketing',
          auth: ['admin', 'elysian', 'demo'],
          element: <ChatApp
            org={{key: "Elysian", name: "Elysian Senior Homes"}}
            collection="Elysian-1"
            level1='Pricing and Marketing'
            pageTitle="Pricing and Marketing - Elysian Resources"
            pageDescription="BrightSource AI Assistant"
            chatHeader={<span>Pricing and Marketing</span>}
          />,
          children: [
            {
              path: '',
              auth: ['admin', 'elysian', 'demo'],
              element: <ChatApp
                org={{key: "Elysian", name: "Elysian Senior Homes"}}
                collection="Elysian-1"
                level1='Pricing and Marketing'
                pageTitle="Pricing and Marketing - Elysian Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Pricing and Marketing</span>}
              />,
            },
            {
              path: 'Chanhassen',
              auth: ['admin', 'elysian', 'demo'],
              element: <ChatApp
                org={{key: "Elysian", name: "Elysian Senior Homes"}}
                collection="Elysian-1"
                level1='Pricing and Marketing'
                level2='Chanhassen'
                pageTitle="Pricing and Marketing - Chanhassen Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Pricing and Marketing for Chanhassen</span>}
              />,
            },

            {
              path: 'DowntownPlaza',
              auth: ['admin', 'elysian', 'demo'],
              element: <ChatApp
                org={{key: "Elysian", name: "Elysian Senior Homes"}}
                collection="Elysian-1"
                level1='Pricing and Marketing'
                level2='Downtown Plaza'
                pageTitle="Pricing and Marketing - Downtown Plaza Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Pricing and Marketing for Downtown Plaza</span>}
              />,
            },
            {
              path: 'Duluth',
              auth: ['admin', 'elysian', 'demo'],
              element: <ChatApp
                org={{key: "Elysian", name: "Elysian Senior Homes"}}
                collection="Elysian-1"
                level1='Pricing and Marketing'
                level2='Duluth'
                pageTitle="Pricing and Marketing - Duluth Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Pricing and Marketing for Duluth</span>}
              />,
            },
            {
              path: 'LakeCity',
              auth: ['admin', 'elysian', 'demo'],
              element: <ChatApp
                org={{key: "Elysian", name: "Elysian Senior Homes"}}
                collection="Elysian-1"
                level1='Pricing and Marketing'
                level2='Lake City'
                pageTitle="Pricing and Marketing - LakeCity Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Pricing and Marketing for LakeCity</span>}
              />,
            },
            {
              path: 'Northfield',
              auth: ['admin', 'elysian', 'demo'],
              element: <ChatApp
                org={{key: "Elysian", name: "Elysian Senior Homes"}}
                collection="Elysian-1"
                level1='Pricing and Marketing'
                level2='Northfield'
                pageTitle="Pricing and Marketing - Northfield Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Pricing and Marketing for Northfield</span>}
              />,
            },
            {
              path: 'Chanhassen',
              auth: ['admin', 'elysian', 'demo'],
              element: <ChatApp
                org={{key: "Elysian", name: "Elysian Senior Homes"}}
                collection="Elysian-1"
                level1='Pricing and Marketing'
                level2='Chanhassen'
                pageTitle="Pricing and Marketing - Chanhassen Resources"
                pageDescription="BrightSource AI Assistant"
                chatHeader={<span>Pricing and Marketing for Chanhassen</span>}
              />,
            },



          ]
        },
      ],
    },
  ],
};

export default ElysianRoutes;
