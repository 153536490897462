import React, {lazy} from 'react';
const Layout = lazy(() => import('../modules/ChatApp/ChatAppLayout'));
const ChatApp = lazy(() => import('../modules/ChatApp/ChatApp')); 

/*
brightsourceSubscription: "ALL", 
domain: "https://TheGenevaSuites.com/", 
homepage: "/TheGenevaSuites", 
id: "ZremRS8iZSVi2LGm6oqW",
key: "TheGenevaSuites",
name: "The Geneva Suites"
role: "thegenevasuites"
*/

const Routes = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'TheGenevaSuites',
      element: <Layout />,
      auth: ['admin', 'thegenevasuites'],
      children: [
        {
            path: '',
            auth: ['admin', 'thegenevasuites'],
            element: <ChatApp 
              org={{id: "ZremRS8iZSVi2LGm6oqW", key: "TheGenevaSuites", name: "The Geneva Suites"}}
              collection="TheGenevaSuites-v1"
              pageTitle="The Geneva Suites AI"
              pageDescription="Your virtual asssistant to Geneva Suites resources."
            />,
        },
        {
            path: 'GeneralPolicies',
            auth: ['admin', 'thegenevasuites'],
            element: <ChatApp 
              org={{id: "ZremRS8iZSVi2LGm6oqW", key: "TheGenevaSuites", name: "The Geneva Suites"}}
              collection="TheGenevaSuites-v1"
              level1="General Policies"
              pageTitle="The Geneva Suites General Policies AI"
              pageDescription="Your virtual asssistant to Geneva Suites General Policies Resources."
            />,
        },
        {
            path: 'InfectionControl',
            auth: ['admin', 'thegenevasuites'],
            element: <ChatApp 
              org={{id: "ZremRS8iZSVi2LGm6oqW", key: "TheGenevaSuites", name: "The Geneva Suites"}}
              collection="TheGenevaSuites-v1"
              level1="Infection Control"
              pageTitle="The Geneva Suites Infection Control AI"
              pageDescription="Your virtual asssistant to Geneva Suites Infection Control Resources."
            />,
        },
        {
            path: 'MedicationsTreatment',
            auth: ['admin', 'thegenevasuites'],
            element: <ChatApp 
              org={{id: "ZremRS8iZSVi2LGm6oqW", key: "TheGenevaSuites", name: "The Geneva Suites"}}
              collection="TheGenevaSuites-v1"
              level1="Medications & Treatment"
              pageTitle="The Geneva Suites Medications & Treatment AI"
              pageDescription="Your virtual asssistant to Geneva Suites Medications & Treatment Resources."
            />,
        },
        {
            path: 'Nursing',
            auth: ['admin', 'thegenevasuites'],
            element: <ChatApp 
              org={{id: "ZremRS8iZSVi2LGm6oqW", key: "TheGenevaSuites", name: "The Geneva Suites"}}
              collection="TheGenevaSuites-v1"
              level1="Nursing"
              pageTitle="The Geneva Suites Nursing AI"              
              pageDescription="Your virtual asssistant to Geneva Suites Nursing Resources."
            />,
        },
        {
            path: 'Regulations',
            auth: ['admin', 'thegenevasuites'],
            element: <ChatApp 
              org={{id: "ZremRS8iZSVi2LGm6oqW", key: "TheGenevaSuites", name: "The Geneva Suites"}}
              collection="TheGenevaSuites-v1"
              level1="Regulations"
              pageTitle="The Geneva Suites Regulations AI"
              pageDescription="Your virtual asssistant to Geneva Suites Regulations Resources."
            />,
        },
      ]
    }
    ]
}




export default Routes; 